
import MetisMenu from "metismenujs/dist/metismenujs";
import { generateMenu } from "./menu";
import moment from "moment";
import {
  layoutComputed,
  chatsActions,
  chatsGetters,
  chatsMutations,
  generalChatsGetters,
} from "~/store/helpers";
import { mapState } from "vuex";
import Pusher from "pusher-js";
import debounce from "lodash/debounce";

/**
 * Sidebar component
 */
export default {
  data() {
    return {
      itemId: null,
      loadingNewPatients: false,
      loading: false,
      loadingChats: true,
      perPage: 20,
      currentPage: 1,
      patientListCopy: [],
      pusher: null,
      isScrollActive: false,
      channel: null,
      text: null,
      observer: null,
      timer: null,
      flag: null,
      channel: null,
      resizeObserver: null, // For observing size changes
      mutationObserver: null,
      value: null,
      isMenuCondensed: null,
      channelNewMessages: null,
      searchPatientInput: null,
      adminMenuStyle: {
        color: "#7B8190",
        margin: "0px 10px",
        "font-weight": "450",
      },
      previousRoute: null,
      chatRoute: null,
    };
  },
  props: {
    type: {
      type: String,
      required: true,
    },
    width: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...layoutComputed,
    ...chatsGetters,
    ...generalChatsGetters,
    ...mapState("chat", ["unreadCount"]),
    ...mapState("modules/layout", ["isCondensed"]),

    role() {
      return this.$auth.user ? this.$auth.user.all_roles : [];
    },
    showNeedAssistanceText() {
      return false;
    },
    logoRedirect() {
      return {
        name: this.$vianovaConfig.options.home_path ?? "patients",
      };
    },
    shouldShowChatNotifications() {
      return this.$vianovaConfig.options.dashboard_chat_notifications ?? true;
    },
    chatsLoading() {
      return this.getLoadingChats();
    },
    patientsList(state) {
      return this.getChatPatients();
    },
    allPatients(state) {
      return this.getSearchedPatients();
    },
    navItems() {
      return generateMenu(this.$route, this.$auth.user.all_roles, this).filter(
        (item) => {
          if (item.roles && item.roles.length > 0) {
            return item.roles.some((e) =>
              this.$auth.user.all_roles.includes(e)
            );
          } else {
            return true;
          }
        }
      );
    },
    userGroups() {
      const groups = this.$store.getters["modules/groups/getUserGroups"]();
      return groups || [];
    },
    historicalGroups() {
      const groups =
        this.$store.getters["modules/groups/getHistoricalGroups"]();
      return groups || [];
    },
    isUserOverviewPage() {
      return (
        this.$route.name === "civiclee-users-id-overview" ||
        (this.$route.path.includes("/civiclee/users/") &&
          this.$route.path.includes("/overview"))
      );
    },
    customTasks() {
      return this.$store.getters[
        "modules/care_plan_activities/getCustomTasks"
      ]();
    },
    selectedCohort() {
      return this.$store.getters["modules/groups/getSelectedCohort"]();
    },
    cohortsLoading() {
      return this.$store.getters["modules/groups/isLoading"]();
    },
  },
  created() {
    this.$store.commit(
      "modules/dashboard/SET_STEP",
      `${localStorage.getItem("previousStep")}`
    );
  },
  mounted() {
    this.checkScroll();
    this.$nextTick(() => {
      // Initialize metisMenu only if side-menu exists
      const sideMenu = document.getElementById("side-menu");
      if (sideMenu) {
        // eslint-disable-next-line no-unused-vars
        var menuRef = new MetisMenu("#side-menu");
      }
      this._activateMenuDropdown();

      // Observe size and content changes
      this.observeSizeChanges();
      this.observeContentChanges();
      window.addEventListener("resize", this.checkScroll);
      setTimeout(() => {
        const resizeEvent = new Event("resize");
        window.dispatchEvent(resizeEvent);
      }, 500);
    });

    this.$router.afterEach((routeTo, routeFrom) => {
      // if (!routeTo || !routeFrom) return
      this.chatRoute
        ? localStorage.setItem("chatRoute", this.chatRoute)
        : (this.chatRoute = localStorage.getItem("chatRoute"));
      if (
        routeFrom.name != "users-id-overview" &&
        routeFrom.name != "users-id-calendar" &&
        routeFrom.name != "users-id-care-plan" &&
        !routeTo.name.includes("admin") &&
        !routeFrom.name.includes("admin")
      ) {
        this.$store.commit("modules/dashboard/SET_STEP", `${routeFrom.name}`);
        localStorage.setItem("previousStep", routeFrom.name);
      }
      if (routeTo.name == "admin-index-users" && routeFrom.name == "admin") {
        this.previousRoute = null;
        localStorage.setItem("previousRoute", null);
        this.previousStep = null;
        localStorage.setItem("previousStep", null);
      }
      if (routeTo.name === "chats" && !routeFrom.name.includes("chats")) {
        this.chatRoute =
          routeFrom.name == "index-name"
            ? routeFrom.name
            : routeFrom.name.includes("onboarding")
            ? "onboarding"
            : routeFrom.fullPath;
      }
      if (
        (routeFrom.name == "index-name" && routeTo.name.includes("users")) ||
        (routeFrom.name == "patients" && routeTo.name.includes("users"))
      ) {
        this.previousRoute =
          routeFrom.name == "users-id-overview" ? "index-name" : routeFrom.name;
        localStorage.setItem("previousRoute", this.previousRoute);
      } else if (!routeTo.name.includes("users")) {
        this.previousRoute = null;
        localStorage.setItem("previousRoute", null);
      }
      this._activateMenuDropdown();
    });

    this.$store.dispatch("chat/getUnreadMessageCount");
    window.addEventListener("resize", this.getWindowWidth);
    this.setLogoutTimer();
    let channelName = `trackers.${localStorage.getItem("organizationId")}`;
    this.pusher = new Pusher(this.$connect.pusherAppKey, {
      cluster: "mt1",
    });
    this.channel = this.pusher.subscribe(channelName);

    console.log(`subscribed to channel ${channelName}`, this.channel);

    // if (!this.$veruFlag("chat")) {

    this.channelNewMessages = this.pusher.subscribe(
      `user_${this.$auth.user.id}`
    );
    console.log(
      `subscribed to channel "user_${this.$auth.user.id}""`,
      this.channelNewMessages
    );
    this.channelNewMessages.bind("new-message-event", async (event) => {
      if (event.message.sender_id == this.$auth.user.id) return;

      console.log(`received new message event`, event);
      if (this.lastMessageId === event.message.id) return;
      this.lastMessageId = event.message.id;
      let body = JSON.parse(event.message.body);

      if (this.shouldShowChatNotifications && this.$route.name !== "chats") {
        this.makeToast(
          {
            ...event.message,
            body: body,
          },
          true
        );
      }

      await this.$store.dispatch("chat/receivedNewMessageNotification", event);
      this.$store.commit("general_chat/SET_UNREAD_CHATS", event);
    });
  },
  watch: {
    navItems(val) {
      this.checkScroll();
    },
    patientsList(val) {
      this.patientListCopy = val;
    },
    $route: {
      immediate: true,
      deep: true,

      handler(oldVal, newVal) {
        setTimeout(() => {
          const resizeEvent = new Event("resize");
          window.dispatchEvent(resizeEvent);
        }, 500);
        if (!oldVal || !newVal) return;
        if (oldVal !== newVal)
          this.$store.commit("modules/layout/SET_CONDENSED", false);
      },
    },
  },
  beforeDestroy() {
    console.log("destroyed...");
    if (this.pusher) {
      // this.pusher.unsubscribe(`conversation_${this.conversation_id}`);
      // console.log("unsubscribed...");
    }
    if (this.channel) {
      // this.channel.unsubscribe();
      this.channel.unbind();
      this.channel = null;
      console.log("unbinded...");
    }
    if (this.channelNewMessages) {
      this.channelNewMessages.unbind();
      this.channelNewMessages = null;
      console.log("unbinded...");
    }
    this.pusher = null;
    window.removeEventListener("resize", this.updateWindowWidth);
  },
  methods: {
    ...chatsActions,
    ...chatsMutations,
    makeToast(message, append = false) {
      const h = this.$createElement;
      const displayText =
        this.formatTextSize(message.body?.text) || this.$t("chat.new_message");
      const vNodesMsg = h("p", { class: ["text-center", "mb-0"] }, [
        `${displayText}`,
        " ",
        h("br"),
        h(
          "a",
          {
            attrs: {
              href: `/chats/?cid=${message.conversation_id}`,
              target: "_blank",
            },
          },
          this.$t("chat.read_now")
        ),
      ]);
      // Create the title
      const vNodesTitle = h(
        "div",
        { class: ["d-flex", "flex-grow-1", "align-items-baseline", "mr-2"] },
        [
          h("strong", { class: "mr-2" }, `${message.sender.display_name}`),
          h(
            "small",
            { class: "ml-auto text-italics" },
            `${moment(message.created_at).fromNow()}`
          ),
        ]
      );
      // Pass the VNodes as an array for message and title
      this.$bvToast.toast([vNodesMsg], {
        title: [vNodesTitle],
        solid: true,
        variant: "info",
        noAutoHide: true,
        appendToast: append,
      });

      // this.$bvToast.toast(`${message.body.text}`, {
      //   title: `Message from ${message.sender.display_name}`,
      //   variant: "primary",

      //   appendToast: append,
      // });
    },
    setLogoutTimer() {
      this.timer = setInterval(() => {
        try {
          if (this.$auth.strategy.token.status().expired()) {
            clearInterval(this.timer);
            Swal.fire({
              title: "Session Expired",
              text: "Your session has expired and you have been automatically logged out!",
              icon: "warning",
              allowOutsideClick: false,
              allowEscapeKey: false,
              showCancelButton: false,
              confirmButtonColor: "#74788D",
              cancelButtonColor: "#F46A6A",
              confirmButtonText: "Log In Again",
            }).then((result) => {
              this.$router.push({
                name: "auth-login",
              });
            });
          }
        } catch (e) {}
      }, 1000);
    },
    getFirstPatient(patient) {
      if (!patient) return;
      this.$router.push({
        name: `chats-index-id-profile`,
        params: {
          id: patient.conversation.participants.find(
            (e) => e.user.id != patient.messageable_id
          ).messageable_id,
          personOrganizationId: patient.conversation.participants.find(
            (e) => e.user.id != patient.messageable_id
          ).messageable?.organization_id,
        },
        query: {
          cid: patient.conversation_id,
          t: Date.now(),
          patient: patient.conversation.participants.find(
            (e) => e.user.id != patient.messageable_id
          ).user?.display_name,
        },
      });
    },
    addNewPatient(event) {
      let patientHasConversation = this.patientsList.find((patient) =>
        patient.conversation.participants.some(
          (participant) => participant.messageable_id == event.id
        )
      );

      if (!patientHasConversation) {
        if (event.id) {
          this.$axios
            .$post(
              `${localStorage.getItem("organizationId")}/chat/start/${event.id}`
            )
            .then((res) => {
              this.$axios
                .$get(
                  `${localStorage.getItem("organizationId")}/chat/${
                    res.data.id
                  }?page=${1}&per_page=${20}`
                )
                .then(async () => {
                  let result = await this.fetchChatPatients({
                    organizationId: localStorage.getItem("organizationId"),
                    currentPage: this.currentPage,
                    perPage: this.perPage,
                  });
                  if (result) {
                    this.getFirstPatient(
                      result.data.find(
                        (patient) =>
                          this.getPatientName(patient) == event.display_name
                      )
                    );
                  }
                });
            });
        }
      } else {
        let mappedList = JSON.parse(JSON.stringify(this.patientsList));
        mappedList.splice(
          0,
          0,
          mappedList.splice(
            mappedList.findIndex((e) => patientHasConversation.id == e.id),
            1
          )[0]
        );
        this.SET_PATIENTS(mappedList);
        this.getFirstPatient(mappedList[0]);
        this.searchPatientInput = null;
      }
      this.searchNewPatient = null;
      this.$bvModal.hide("addPatient");
    },
    debounceSearchPatients: debounce(function (value) {
      this.searchPatients(value);
    }, 50),
    async searchPatients(value) {
      await this.searchAllPatients(value, true);
    },
    searchAllPatients(value, inbox = true) {
      this.loading = true;
      if (this.timer) {
        clearTimeout(this.timer);
      }
      this.timer = setTimeout(
        () =>
          this.searchedPatientsWithInbox({
            organizationId: localStorage.getItem("organizationId"),
            currentPage: 1,
            perPage: this.perPage,
            search: value,
            inbox: inbox,
          }).finally(() => (this.loading = false)),
        300
      );
    },
    observeMenuChanges() {
      const targetNode = document.body; // Set the root node to observe
      const config = { attributes: true, childList: true, subtree: true }; // Observe all changes

      // Callback for MutationObserver
      const callback = () => {
        this.checkMenuCondensed();
      };

      // Initialize and start the observer
      this.observer = new MutationObserver(callback);
      this.observer.observe(targetNode, config);
    },
    checkMenuCondensed() {
      this.isMenuCondensed = document.querySelector(".mm-collapse");
    },
    getPatientName(patient) {
      return patient.conversation.participants.find((participant) =>
        participant.messageable_type.includes("Patient")
      )
        ? patient.conversation.participants.find((participant) =>
            participant.messageable_type.includes("Patient")
          ).user.display_name
        : "Patient";
    },
    checkScroll() {
      const container = document.querySelector(".simplebar-content-wrapper");
      if (container) {
        // Check if the scroll is active;
        this.isScrollActive = container.scrollHeight > container.clientHeight;
        this.$forceUpdate();
        console.log(this.isScrollActive, "scroll");
      }
    },
    observeSizeChanges() {
      const container = document.querySelector(".simplebar-content-wrapper");
      if (!container) return;

      // Use ResizeObserver to watch for size changes
      this.resizeObserver = new ResizeObserver(() => {
        this.checkScroll();
      });
      this.resizeObserver.observe(container);
    },
    observeContentChanges() {
      const container = document.querySelector(".simplebar-content-wrapper");
      if (!container) return;

      // Use MutationObserver to watch for content changes
      this.mutationObserver = new MutationObserver(() => {
        this.checkScroll();
      });
      this.mutationObserver.observe(container, {
        childList: true, // Watch for additions/removals of children
        subtree: true, // Watch all descendants
        characterData: true, // Watch changes to text nodes
      });
    },
    momentTime(date) {
      let duration = moment.duration(moment().diff(date));
      let hours = duration.asHours();
      let minutes = duration.asMinutes();
      let seconds = duration.asSeconds();
      let days = duration.asDays();
      let months = duration.asMonths();
      let years = duration.asMonths();
      return seconds <= 60
        ? `${Math.round(seconds)} ${this.$t("chats.message_times.sec")}`
        : minutes <= 60
        ? `${Math.round(minutes)} ${this.$t("chats.message_times.min")}`
        : hours <= 24
        ? `${Math.round(hours)} ${this.$t("chats.message_times.hr")}`
        : days <= 31
        ? `${Math.round(days)} ${this.$t("chats.message_times.d")}`
        : months <= 12
        ? `${Math.round(months)} ${this.$t("chats.message_times.mon")}`
        : `${Math.round(years)} ${this.$t("chats.message_times.yr")}`;
    },
    previousRouteComp() {
      return localStorage.getItem("previousRoute");
    },

    /**
     * Toggle menu
     */
    toggleMenu() {
      this.$parent.$parent.toggleMenu();
      this.$store.commit("modules/layout/SET_CONDENSED", true);
    },
    /**
     * Returns true or false if given menu item has child or not
     * @param item menuItem
     */
    hasItems(item) {
      return item.subItems !== undefined ? item.subItems.length > 0 : false;
    },
    /**
     * remove active and mm-active class
     */
    _removeAllClass(className) {
      const els = document.getElementsByClassName(className);
      if (!els.length) return;

      while (els[0]) {
        if (
          els[0].children[0]?.children[0] &&
          els[0].children[0].children[0].className == "is-online"
        ) {
          els[0].children[0].children[0].style.display = "block";
        }
        els[0].classList.remove(className);
      }
    },
    formatTextSize(text) {
      if (text.length > 100) {
        return text.substring(0, 100) + "...";
      }
      return text;
    },
    _activateMenuDropdown() {
      this._removeAllClass("mm-active");
      this._removeAllClass("mm-show");
      this.itemId = this.$route.params.id;

      this.$nextTick(() => {
        const sideMenu = document.getElementById("side-menu");
        if (!sideMenu) return;

        var links = document.getElementsByClassName("side-nav-link-ref");
        if (!links.length) return;

        var matchingMenuItem = null;
        const paths = [];

        for (var i = 0; i < links.length; i++) {
          paths.push(links[i]["pathname"]);
        }

        let pathName =
          this.$route.name === "reports"
            ? "/reports"
            : this.$route.name == "index-name"
            ? "/"
            : window.location.pathname.includes("/chats")
            ? "/chats"
            : window.location.pathname.includes("admin/tracks")
            ? "/admin/tracks"
            : window.location.pathname;

        var itemIndex = paths.indexOf(pathName);

        if (itemIndex === -1) {
          const strIndex = window.location.pathname.lastIndexOf("/");
          const item = window.location.pathname.substr(0, strIndex).toString();
          matchingMenuItem = links[paths.indexOf(item)];
        } else {
          matchingMenuItem = links[itemIndex];
        }

        if (matchingMenuItem) {
          matchingMenuItem.classList.add("active");
          var parent = matchingMenuItem.parentElement;
          /**
           * TODO: This is hard coded way of expading/activating parent menu dropdown and working till level 3.
           * We should come up with non hard coded approach
           */
          if (parent) {
            parent.classList.add("mm-active");
            if (
              parent.children[0].children[0] &&
              parent.children[0].children[0].className == "is-online"
            ) {
              parent.children[0].children[0].style.display = "none";
            }
            const parent2 = parent.parentElement.closest("ul");
            if (parent2 && parent2.id !== "side-menu") {
              parent2.classList.add("mm-show");
              const parent3 = parent2.parentElement;

              if (parent3) {
                parent3.classList.add("mm-active");
                var childAnchor = parent3.querySelector(".has-arrow");
                var childDropdown = parent3.querySelector(".has-dropdown");
                if (childAnchor) childAnchor.classList.add("mm-active");
                if (childDropdown) childDropdown.classList.add("mm-active");

                const parent4 = parent3.parentElement;
                if (parent4 && parent4.id !== "side-menu") {
                  parent4.classList.add("mm-show");
                  const parent5 = parent4.parentElement;
                  if (parent5 && parent5.id !== "side-menu") {
                    parent5.classList.add("mm-active");
                    const childanchor = parent5.querySelector(".is-parent");
                    if (childanchor && parent5.id !== "side-menu") {
                      childanchor.classList.add("mm-active");
                    }
                  }
                }
              }
            }
          }
        }
      });
    },
    capitalizeFirstLetter(string) {
      if (!string) return;
      let splitWord = string.includes("index")
        ? string?.split("index-")
        : string?.split("id-");
      splitWord = splitWord[splitWord.length - 1].includes("-")
        ? "Lab Vitals"
        : splitWord[splitWord.length - 1];
      return splitWord.slice(0, 1).toUpperCase() + splitWord.slice(1);
    },
    selectCohort(cohort) {
      this.$store.dispatch("modules/groups/selectCohort", cohort);
      this.$emit("cohort-selected", cohort);
    },
    isSelectedCohort(cohort) {
      const selectedCohort =
        this.$store.getters["modules/groups/getSelectedCohort"]();
      return selectedCohort && selectedCohort.id === cohort.id;
    },
    selectCustomTasks() {
      this.$store.dispatch("modules/groups/selectCohort", null);
      this.$emit("cohort-selected", null);
    },
  },
};
